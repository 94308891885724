<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="
      h-screen
      flex
      w-full
      bg-img
      vx-row
      no-gutter
      justify-center
      items-center
    "
  >
    <div
      class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4"
      style="transform: translateY(-50%)"
    >
      <vx-card>
        <div class="vx-card__title mb-6">
          <h2 class="text-center">
            Password changed successfully. Now you can login to the app using
            new password.
          </h2>
        </div>
        <div style="text-align: center">
          <vs-button @click="logout" color="danger" style="margin-right: 20px"
            >Logout</vs-button
          >
          <vs-button @click="goToProfile">Not Now</vs-button>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
export default {
  data() {
    return {
      value1: "",
    };
  },
  methods: {
    logout() {
      // if user is logged in via auth0
      //if (this.$auth.profile) this.$auth.logOut();

      // if user is logged in via firebase
      const firebaseCurrentUser = firebase.auth().currentUser;

      if (firebaseCurrentUser) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.push("/login").catch(() => {});
          });
      }

      // If JWT login
      if (
        localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
        )
      ) {
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}TokenExpiry`
        );
        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`);
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`
        );

        this.$cookies.remove(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`
        );
        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`);
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        );
        this.$router.push("/login").catch(() => {});
      }

      // Change role on logout. Same value as initialRole of acj.js
      this.$acl.change("admin");
      localStorage.removeItem("userInfo");

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push("/login").catch(() => {});
    },
    goToProfile() {
      if (this.$store.state.AppActiveUser.userType === "superAdmin")
        this.$router.push("/super-admin/profile");
      else
        this.$router.push(
          "/" + this.$store.state.AppActiveUser.userType + "/profile"
        );
    },
  },
};
</script>
